import React from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner-v2";
import AboutV3 from "./section-components/about-v3";
import Service from "./section-components/service-v2";
import UpComingProduct from "./section-components/upcoming-product-v1";
import Testimonial from "./section-components/testimonial-v2";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import ProductSliderV1 from "./section-components/product-slider-v1";
import Sponsor from "./section-components/sponsor-v1";
import CategoryV3 from "./section-components/category-v3";

const   Home_V1 = () => {
  return (
    <div>
      <Navbar />
      <Banner />
      <AboutV3 />
      <ProductSliderV1 />
      <Service />
      <UpComingProduct />
      <Sponsor />
      <CategoryV3 />
      <Testimonial />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Home_V1;
