import React, { Component } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";
import Copyright from "./copyright";

class Footer_v1 extends Component {
  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      <img
                        src={publicUrl + "assets/img/logo-2.png"}
                        alt="Logo"
                        width={100}
                      />
                    </div>
                  </div>
                  <p>
                    URA Homes is an online platform that allows real estate
                    developers in the UAE to market their projects and connects
                    them to buyers worldwide. Our platform offers a constantly
                    updated selection of authentic and high-quality real estate
                    investments, carefully curated by industry experts. Our aim
                    is to provide a global audience with unparalleled access to
                    these opportunities.
                  </p>
                  <p>
                    At URA Homes, our team of talented and passionate
                    professionals brings together extensive experience in the
                    UAE real estate industry, digital marketing, and
                    advertising. Each member contributes years of relevant work
                    experience, making our team our company's greatest strength
                    and asset.
                  </p>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Company</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/developers">Developers</Link>
                      </li>
                      <li>
                        <Link to="/contact">Why Dubai</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Services</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/legal">Legal</Link>
                      </li>

                      <li>
                        <Link to="/about">Terms &amp; Conditions</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Social</h4>
                  <div className="footer-menu go-top">
                    <div className="footer-address">
                      <ul>
                        <li>
                          <div className="footer-address-icon">
                            <i className="icon-placeholder" />
                          </div>
                          <div className="footer-address-info">
                            <p>Brooklyn, New York, United States</p>
                          </div>
                        </li>
                        <li>
                          <div className="footer-address-icon">
                            <i className="icon-call" />
                          </div>
                          <div className="footer-address-info">
                            <p>
                              <a href="tel:+0123-456789">+0123-456789</a>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="footer-address-icon">
                            <i className="icon-mail" />
                          </div>
                          <div className="footer-address-info">
                            <p>
                              <a href="mailto:example@example.com">
                                example@example.com
                              </a>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__social-media mt-20">
                      <Social />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Copyright />
      </footer>
    );
  }
}

export default Footer_v1;
