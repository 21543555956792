import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

	return <div className="ltn__feature-area pt-90 pb-90 go-top">
			<div className="container">
			<div className="row">
				<div className="col-lg-12">
				<div className="section-title-area ltn__section-title-2--- text-center">
					<h1 className="section-title">Our Core Values</h1>
				</div>
				</div>
			</div>
			<div className="row ltn__custom-gutter">
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
					<div className="ltn__feature-icon">
					<span><i className="flaticon-apartment" /></span>
					</div>
					<div className="ltn__feature-info">
					<h4><Link to="/service-details">U - Urban</Link></h4>
					<p>A term used to describe the style, culture, and vibe of city life, often associated with modern, trendy, and fashionable things.</p>
					</div>
				</div>
				</div>
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6 active">
					<div className="ltn__feature-icon">
					<span><i className="flaticon-park" /></span>
					</div>
					<div className="ltn__feature-info">
					<h4><Link to="/service-details">R - Realty</Link></h4>
					<p>A term used to describe the business of buying, selling, and managing real estate properties, often associated with the legal and financial aspects of the real estate industry.</p>
					</div>
				</div>
				</div>
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
					<div className="ltn__feature-icon">
					<span><i className="flaticon-maps-and-location" /></span>
					</div>
					<div className="ltn__feature-info">
					<h4><Link to="/service-details">A - Advisor </Link></h4>
					<p>Professionals who provide expert guidance and advice to clients on buying, selling, or managing real estate properties, often associated with the legal, financial, and strategic aspects of the real estate industry.</p>
					</div>
				</div>
				</div>
				
			</div>
			<p>So, "Urban Realty Advisors" refers to a company or group of professionals who specialize in providing advice and guidance on buying, selling, or managing real estate properties located in densely populated areas of cities or towns.</p>
			</div>
		</div>
  
        }
}

export default ServiceV2