import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";
import HomeV1 from "./components/home-v1";
import About from "./components/about";
import ComingSoon from "./components/coming-soon";
import Error from "./components/404";
import Contact from "./components/contact";
import Developers from "./components/developers/developers";
import Whydubai from "./components/whydubai/whydubai";
import Legal from "./components/legal/legal";

class Root extends Component {
  render() {
    return (
      <HashRouter basename="/">
        <div>
          <Switch>
            <Route exact path="/" component={HomeV1} />
            <Route path="/about" component={About} />
            <Route path="/legal" component={Legal} />
            <Route path="/coming-soon" component={ComingSoon} />
            <Route path="/404" component={Error} />
            <Route path="/contact" component={Contact} />
            <Route path="/developers" component={Developers} />
            <Route path="/why-dubai" component={Whydubai} />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("URA"));
