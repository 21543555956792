import React from 'react';
import Navbar from '../global-components/navbar';
import ServiceDetails from '../section-components/service-details';
import CallToActionV1 from '../section-components/call-to-action-v1';
import Footer from '../global-components/footer';

const Legal = () => {
    return <div>
        <Navbar />
        <ServiceDetails />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Legal

